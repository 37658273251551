import React from "react";

interface Props {
    fill: string;
}

export const ArtProductionIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} className="icon">
            <path d="M0.1,17.9c0,0,3.3,0,4.4-1.2C5.7,15.6,5.9,13.9,5,13c-0.9-0.9-2.6-0.7-3.7,0.5C0.1,14.7,0.1,17.9,0.1,17.9z" />
            <path d="M17.6,2.1l-1.8-1.8c-0.4-0.4-1.1-0.4-1.5,0.1L12,3.2L14.8,6l2.8-2.4C18,3.2,18.1,2.5,17.6,2.1z" />
            <path d="M11.3,3.9L6,10.1c-0.5,0.6-0.6,1.4-0.1,1.8l0.2,0.2c0.4,0.4,1.2,0.4,1.8-0.1l6.2-5.3L11.3,3.9z" />
        </svg>
    )
}