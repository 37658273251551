import React from "react";

interface Props {
    fill: string;
}

export const AssetDesignIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M8.6,9.4C8.4,7.6,10,6.2,11.7,6.2c2.2,0,3.1,1.4,3.1,3.1c0,1.7-1.3,2.8-3.1,3.1C10,12.8,8.8,11.1,8.6,9.4z" />
            <path d="M17.2,9.4c0-1.3,1.1-2.8,2.3-2.3c1.2,0.4,2.3,1.1,2.3,2.3c0,1.3-0.5,2.9-2.3,2.3C18.3,11.4,17.2,10.7,17.2,9.4z" />
            <path d="M4.7,15.6c0-2,1.1-2.3,2.3-2.3c1.3,0,2,1.1,2.3,2.3C9.8,17.1,8.3,18,7,18C5.7,18,4.7,16.9,4.7,15.6z" />
            <path d="M14.5,0.4C6.7,0.4,0.4,6.7,0.4,14.5c0,7.8,6.3,14.1,14.1,14.1c1.3,0,2.3-1,2.3-2.3c0-0.6-0.2-1.2-0.6-1.6
            c-0.4-0.4-0.6-1-0.6-1.5c0-1.3,1-2.3,2.3-2.3h2.8c4.3,0,7.8-3.5,7.8-7.8C28.6,6,22.3,0.4,14.5,0.4z M20.7,17.6h-2.4
            c-2.6,0-5.1,1.7-5.7,4.3c-0.2,0.7-0.2,1.5,0,2.2c0.1,0.6-0.4,1.1-1,0.9c-5.3-1.4-9-6.7-7.9-12.6c1-5.5,5.8-9.1,11.4-8.9
            c5.8,0.3,10.4,4.4,10.4,9.4C25.5,15.5,23.3,17.6,20.7,17.6z"/>
        </svg>
    )
}