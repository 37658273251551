import React from "react";
import { ArtProductionIcon } from "../../components/layout/icons/ArtProductionIcon";
import { ConceptArtIcon } from "../../components/layout/icons/ConceptArtIcon";
import { UIUXIcon } from "../../components/layout/icons/UIUXIcon";
import { AssetDesignIcon } from "../../components/layout/icons/AssetDesignIcon";
import { GameDevelopmentServiceData } from "./GameDesignData";

export const artProductionPageData: GameDevelopmentServiceData = {
    metaPageTitle: "Art Production - iDream Interactive",
    metaKeywords: "video game concept art, video game art, game concept art, character designs, unity assets",
    metaDescription: "We create everything from concept art to in-game assets. Let us polish your user interfaces and make your users love the experience.",
    title: "Art Production",
    subtitle: "We create everything from concept art to in-game assets.  Let us polish your user interfaces and make your users love the experience.",
    icon: <ArtProductionIcon fill="#ffffff" />,
    services: [
        {
            title: "Concept Art",
            description: (<>
                <p>Concept Art is always the first step in coming up with the visuals to a game. We try to apply quick concepts to prototypes to help us determine a look and feel to go alongside a game. This can help us understand the scope of a games design and what technology to use.</p>
            </>),
            icon: <ConceptArtIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Concept art of a jungle",
        },
        {
            title: "UI/UX Design",
            description: (<>
                <p>A games User Interface and User Experience go hand in hand. We always design our interfaces with the users experience in mind. Our interfaces are created to seamlessly transition between different devices so that they adhere to a cohesive design across all platforms.</p>
            </>),
            icon: <UIUXIcon fill="#03041d" />,
            image: "",
            imageAlt: "BOOOM! Casino User Interface",
        },
        {
            title: "Artwork & Asset Design",
            description: (<>
                <p>We can take your idea and create a beautiful world around it. Depending on the style you require, we can design the look and feel of your game as well as the resources to market it.</p>
                <p>We utilize an art toolkit which helps us visually represent every element in the game. This allows us stay cohesive in design and to view the entire collection of game art at a glance.</p>
            </>),
            icon: <AssetDesignIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Recipe Rescue Asset Toolkit",
        },
    ]
}