import React from "react";

interface Props {
    fill: string;
}

export const UIUXIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M21.4,0.5H7.6c-1.7,0-3.1,1.4-3.1,3.1v21.8c0,1.7,1.4,3.1,3.1,3.1h13.8c1.7,0,3.1-1.4,3.1-3.1V3.6
            C24.5,1.9,23.1,0.5,21.4,0.5z M21.4,23.8c0,0.9-0.7,1.6-1.5,1.6H9.1c-0.8,0-1.5-0.7-1.5-1.6V5.2c0-0.9,0.7-1.6,1.5-1.6h10.8
            c0.8,0,1.5,0.7,1.5,1.6V23.8z"/>
            <path d="M16.4,8.7c0,1.1-0.9,2.4-1.9,2.4s-1.9-1.4-1.9-2.4c0-1.1,0.9-1.9,1.9-1.9S16.4,7.6,16.4,8.7z" />
            <path d="M17.5,15.4h-6c-0.5,0-0.9-0.4-0.9-0.9l0,0c0-1.9,1.5-2.6,3.4-2.6h0.9c1.9,0,3.4,0.7,3.4,2.6l0,0C18.3,15,18,15.4,17.5,15.4
		    z"/>
            <path d="M16.8,22.3h-4.6c-0.8,0-1.5-0.7-1.5-1.6v-1.6c0-0.9,0.7-1.6,1.5-1.6h4.6c0.8,0,1.5,0.7,1.5,1.6v1.6
		    C18.3,21.6,17.7,22.3,16.8,22.3z"/>
        </svg>
    )
}